/**
 * The main app file. Calls init on all the components.
 * @author JJE
 */
window.$ = window.jQuery = require('jquery');
require("./components/wcagLinkHandling");
require("./components/navbar");
require("./components/footerMap");

var bootstrap = require('bootstrap-sass');
var list = require("./misc/list.min.js");
var video = require("./components/video");
var heroLoopingVideo = require("./components/hero-looping-video");
var videoPlayer = require("./components/video-player");

var pressCarousel = require("./components/pressdownload-carousel");
var carouselHeader = require("./components/carousel-header");
var fullScreenNav = require("./components/full-screen-nav");
var imageCarousel = require("./components/image-carousel");
var accordion = require("./components/accordion");
var some = require("./components/some");
var caseSearch = require("./components/case-search");   
var jobSearch = require("./components/job-search");
var twoColumns = require("./components/two-columns");
var pageTiles = require("./components/pagetiles");
var pageTiles = require("./components/clickable-icons");
var newsAndLinksComponent = require("./components/news-and-links-component");
var newsAndPressPage = require("./components/news-and-press-page");
var jobEntry = require("./components/job-entry");
var reportSearch = require("./components/report-search");
var acceptmodal = require("./components/acceptmodal");
var heroFieldCarousel = require("./components/hero-field-carousel");
var barChart = require("./components/barChart");
var numberCountUp = require("./components/numberCountUp");
var waypointsSettings = require("./components/waypointsSettings");
var formValidationScroll = require("./components/formValidationScroll");
var iframeAutoHeight = require("./components/iframeAutoHeight");
var eventsRollup = require("./components/events-rollup");
var hoverlist = require("./components/hoverlist.js");
var eventsRollup = require("./components/tile-accordion");
var cardList = require("./components/card-list");

//var heroLoopingVideo = require("./components/hero-looping-video");
//var newsListControll = require("./components/newsListControll");

video.init();


$(window).on('load', function () {
	heroLoopingVideo.init();
});

$(function () {
	videoPlayer.init($('.js-video-player'));
});